<template>
  <div class="bar-echarts">
    <base-echarts :options="options" :height="height" :width="width">
    </base-echarts>
  </div>
</template>

<script setup>
import BaseEcharts from '@/base-ui/echart'
import { computed, ref, defineProps } from 'vue'

const props = defineProps({
  valueName: {
    type: String,
    default: 'Count'
  },
  xLabels: {
    type: Array,
    required: true
  },
  values: {
    type: Array,
    required: true
  }
})

const options = computed(() => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: props.xLabels,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          rotate: 45
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: props.valueName,
        type: 'bar',
        barWidth: '60%',
        data: props.values
      }
    ]
  }
})

const height = ref('420px')
const width = ref('100%')
</script>

<style lang="scss" scoped></style>
