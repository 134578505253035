<template>
  <div class="terminal-report">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="'Terminals Report'"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 15px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #extraHandleBtns>
        <div v-if="isExport" style="text-align: center">
          <ExportModel @exportReport="handleReportExport" />
        </div>
      </template>
    </page-content>
    <ws-card style="margin-top: 15px" :title="'Terminal Statistics'">
      <page-echarts
        :xLabels="$store.getters.terminalXLabels"
        :values="$store.getters.terminalReportValues"
      ></page-echarts>
    </ws-card>
    <progressModal ref="progressModalRef" />
  </div>
</template>

<script setup>
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '../../../components/PageContent/page-content.vue'
import pageEcharts from '@/components/PageEcharts/bar-echarts'
import WsCard from '@/base-ui/card'
import { searchFormConfig } from './config/terminal.report.form'
import { contentTableConfig } from './config/terminal.report.table'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { handleDownloadAndExport } from '@/utils/globalHandler'
import { exportFile } from '@/utils/util'
import i18n from '@/i18n'
import { cloneDeep } from 'lodash'
import { usePermission } from '@/hooks/use-permission'

// 表格基础配置项
const tableOptions = {
  moduleName: 'report', // 所属的模块
  pageName: 'terminalReport', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: false, // 是否显示添加按钮的插槽
  pageAction: 'report/handleGetTerminalReportData', // 获取表格的Action
  deleteRecordAction: 'report/handleDeleteApplication', // 删除record的Action
  pageCountGetter: 'report/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'report/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)
const pageContentRef = ref('')
const pageSearchRef = ref('')

const isExport = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'export'
)

const store = useStore()
const { t } = i18n.global

const getTerminalStatics = (data) => {
  store.dispatch('report/handleTerminalReportList', data)
}
getTerminalStatics({})

const formateData = (data) => {
  const formateObject = cloneDeep(data)
  formateObject.tenantId = data.tenantId ? data.tenantId.join(',') : ''
  return formateObject
}

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(formateData(queryInfo))
  // 需要调用action获取报表数据
  getTerminalStatics(formateData(queryInfo))
}

const handleResetClick = () => {
  pageContentRef.value.getPageData()
  // 处理重置后的数据
  getTerminalStatics({})
}

const progressModalRef = ref(null)

const handleReportExport = async (type) => {
  const form = pageSearchRef.value.getCurrentFieldValues()
  // const params = {
  //   ...form
  //   // ...type,
  //   // timeZone: getCurrentTimeZone()
  // }

  await handleDownloadAndExport(
    progressModalRef,
    store,
    'report/downLoadTerminalReport',
    formateData(form),
    exportFile,
    'xlsx',
    t('menu.report-terminals')
  )
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formateData(formData))
}
</script>
